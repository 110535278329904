import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"


const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 2" />  
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 2</h1>
      <p>God morgen mamma!</p>
      <p>I går sov jeg bort hele dagen på dagtid, så jeg rakk ikke ordne noen ekstra gave til deg.</p>
      <p>La meg heller bare si deg en hemmelighet, som pappa stadig hvisker til meg. Han sier at jeg er verdens heldigste som har deg som mamma. Og da svarer jeg "prrrrrr" som for meg betyr "Ja, jeg vet jo det, og jeg er så glad i henne!".</p>
      <p>Jeg ser frem til en ny og fin dag med deg, mommy!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke